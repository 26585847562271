@charset "utf-8";



// Our variables
$base-font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
$base-font-size:   16px;
$base-font-weight: 400;
$small-font-size:  $base-font-size * 0.875;
$base-line-height: 1.5;

$spacing-unit:     30px;

$text-color:       #111;
$background-color: #eee;
$content-background-color: #fff;
$brand-color:      #881923;
$brand-text-color: #fff;

$grey-color:       #828282;
$grey-color-light: lighten($grey-color, 40%);
$grey-color-dark:  darken($grey-color, 25%);

// Width of the content area
$content-width:    800px;

$on-palm:          600px;
$on-laptop:        800px;


// Use media queries like this:
// @include media-query($on-palm) {
//     .wrapper {
//         padding-right: $spacing-unit / 2;
//         padding-left: $spacing-unit / 2;
//     }
// }
@mixin media-query($device) {
    @media screen and (max-width: $device) {
        @content;
    }
}



// Import partials from `sass_dir` (defaults to `_sass`)
@import
        "base",
        "layout",
        "syntax-highlighting",
	"materialize"
;

h1,h2,h3,h4 {
  color: $brand-color;
}

//custom CSS
.nav-wrapper .brand-logo img {
    height: 48px;
}

.brand-color {
  background-color: $brand-color !important;
}

.content {
  background-color: $content-background-color;
  border: 1px solid darken($brand-color, 5%);
  margin-top: 1em;
}

@include media-query($on-palm) {
  .content {
    border: none;
    margin: 0;
    box-shadow: none;
    width: 100%;
  }
}

@include media-query($on-palm) {
  .photo-header {
      max-height: 10em;
  }
}

.image-header {
  height: 400px;
}

.border-b {
  border-bottom: 2px solid $brand-color;
}

.border-art {
  border: 1px solid darken($brand-color, 5%);
}

nav .nav-wrapper ul {
	a {
		color: $brand-color;
	}

	li {
		list-style-type: none;
	}
}

nav .button-collapse i {
  color: $brand-color;
}

.page {
  // padding-top: 5em;
}

.padding-1 {
  padding: 1em;
}

.margin-top-1 {
  margin-top: 1em;
}
